"use client" // Error components must be Client Components

import React, { useEffect } from "react"
import * as Sentry from "@sentry/nextjs"

export default function Error({ error, reset }: { error: Error & { digest?: string }; reset: () => void }) {
    useEffect(() => {
        // Log the error to an error reporting service
        console.error(error)
        Sentry.captureUnderscoreErrorException({
            err: error,
            pathname: location.pathname
        })
    }, [error])

    return (
        <div className="content-404">
            <div>
                <h1>Something went wrong!</h1>

                <button
                    onClick={
                        // Attempt to recover by trying to re-render the segment
                        () => reset()
                    }>
                    Try again
                </button>
                <br />
                <br />
                <br />
            </div>
        </div>
    )
}
export const revalidate = 300
